const GetPredictionQuery = `
  query getPrediction($_id: String!) {
    predictionById(_id: $_id) {
      _id
      gameId
      prediction
      regionId
      players
      gameCreation
      executionDurationInMs
      processing {
        status
        completionPercentage
      }
      log
      positionInQueue
      queueSize
    }
  }
`;

export default GetPredictionQuery;
