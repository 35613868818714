import React from 'react';
import { Row, Col } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { ROUTE_TO_HOME_PAGE } from '../../constants/routes';
import StatusProcessing from './StatusProcessing';
import StatusQueue from './StatusQueue';
import StatusDone from './StatusDone';

const ErrorOrContent = ({ error, prediction }) => {
  const renderComponents = () => {
    switch (prediction.processing.status) {
      case 'queue':
        return <StatusQueue prediction={prediction} />;
      case 'processing':
        return <StatusProcessing prediction={prediction} />;
      case 'done':
        return <StatusDone prediction={prediction} />;
      default:
        return <Redirect to={{ pathname: ROUTE_TO_HOME_PAGE }} />;
    }
  };

  if (error) {
    return (
      <Row className="no-gutters box">
        <Col className="box-items-container">
          <h4>
            Error: {error.code} - {error.message}
          </h4>
          <p className="mb-0">
            <Link to={ROUTE_TO_HOME_PAGE}>Back to Home</Link>
          </p>
        </Col>
      </Row>
    );
  } else {
    return (
      <Row className="no-gutters box">
        <Col className="box-items-container">{renderComponents()}</Col>
      </Row>
    );
  }
};

export default withRouter(ErrorOrContent);
