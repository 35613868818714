import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Container } from 'reactstrap';
import ReactGA from 'react-ga';

import './scss/style.scss';

import { routes, ROUTE_TO_HOME_PAGE } from './constants/routes';
import Header from './containers/Header';
import Footer from './containers/Footer';

ReactGA.initialize('UA-143766066-1');

const App = () => {
  return (
    <BrowserRouter>
      <>
        <Header />
        <main>
          <Container className="my-5">
            <Switch>
              {routes.map(route => (
                <Route
                  key={route.name}
                  exact={route.exact}
                  path={route.path}
                  name={route.name}
                  component={route.component}
                />
              ))}
              <Redirect to={{ pathname: ROUTE_TO_HOME_PAGE }} />
            </Switch>
          </Container>
        </main>
        <Footer />
      </>
    </BrowserRouter>
  );
};

export default App;
