import { get, isEmpty } from 'lodash-es';

export const errorHandler = (e, liveGameCheck = false) => {
  let code = 500;
  let message = 'Unknown server error';
  const errors = get(e, 'response.errors', []);
  if (!isEmpty(errors)) {
    code = errors[0].code;
    message = errors[0].key === 'ERROR_NO_DATA' && liveGameCheck ? 'Summoner not found' : errors[0].message;
  }
  return { code, message };
};
