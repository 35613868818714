import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
import { client } from '../../config/gqlRequest';
import GetPredictionQuery from '../../operations/getPredictionQuery';
import { Spinner } from '../../containers/Spinner';
import StatusProcessing from './StatusProcessing';
import StatusQueue from './StatusQueue';
import StatusDone from './StatusDone';
import ErrorOrContent from './ErrorOrContent';
import { ROUTE_TO_HOME_PAGE } from '../../constants/routes';
import { errorHandler } from '../../constants/errorHandling';
import Logo from '../../containers/Logo';

class Report extends Component {
  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);

    this.interval = setInterval(() => {
      if (!this.state || this.state.prediction.processing.status !== 'done') {
        client
          .request(GetPredictionQuery, {
            _id: this.props.match.params.id,
          })
          .then(data => this.setState({ prediction: data.predictionById }))
          .catch(e => {
            const error = errorHandler(e);
            this.setState({
              error: { code: error.code, message: error.message },
            });
            clearInterval(this.interval);
          });
      } else {
        clearInterval(this.interval);
      }
    }, 3000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  renderComponents = prediction => {
    switch (prediction.processing.status) {
      case 'queue':
        return <StatusQueue prediction={prediction} />;
      case 'processing':
        return <StatusProcessing prediction={prediction} />;
      case 'done':
        return <StatusDone prediction={prediction} />;
      default:
        return <Redirect to={{ pathname: ROUTE_TO_HOME_PAGE }} />;
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <meta name="og:title" content="Report" />
          <meta name="og:url" content={`https://predict-gg.moky.dev/report/${this.props.match.params.id}`} />
          <meta name="twitter:title" content="Report" />
          <meta name="twitter:url" content={`https://predict-gg.moky.dev/report/${this.props.match.params.id}`} />
        </Helmet>
        <Logo />
        {this.state === null ? (
          <Spinner loaderClassName="color-orange" />
        ) : (
          <ErrorOrContent error={this.state.error} prediction={this.state.prediction} />
        )}
      </>
    );
  }
}

export default withRouter(Report);
