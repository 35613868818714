import React from 'react';
import { Row, Col } from 'reactstrap';

const Footer = () => (
  <footer>
    <Row className="no-gutters align-items-center px-2">
      <Col lg={{ offset: 1, size: 10 }} xl={{ offset: 2, size: 8 }} className="text-center py-2">
        Predict GG isn’t endorsed by Riot Games and doesn’t reflect the views or opinions of Riot Games or anyone
        officially involved in producing or managing League of Legends. League of Legends and Riot Games are trademarks
        or registered trademarks of Riot Games, Inc. League of Legends © Riot Games, Inc.
      </Col>
    </Row>
  </footer>
);

export default Footer;
