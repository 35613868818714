import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';

const DATA_POINTS = [
  'teamId',
  'championId',
  'spell1Id',
  'spell2Id',
  'championMastery',
  'numberOfGames',
  'gameDuration',
  'visionScore',
  'longestTimeSpentLiving',
  'kills',
  'damageDealtToObjectives',
  'totalDamageTaken',
  'damageSelfMitigated',
  'assists',
  'totalDamageDealtToChampions',
  'visionWardsBoughtInGame',
  'deaths',
  'win',
  'firstBloodAssist',
  'totalTimeCrowdControlDealt',
  'tripleKills',
  'neutralMinionsKilled',
  'damageDealtToTurrets',
  'largestMultiKill',
  'wardsKilled',
  'largestKillingSpree',
  'quadraKills',
  'teamObjective',
  'neutralMinionsKilledTeamJungle',
  'firstInhibitorKill',
  'combatPlayerScore',
  'largestCriticalStrike',
  'goldSpent',
  'objectivePlayerScore',
  'totalDamageDealt',
  'neutralMinionsKilledEnemyJungle',
  'wardsPlaced',
  'turretKills',
  'firstBloodKill',
  'goldEarned',
  'killingSprees',
  'unrealKills',
  'firstTowerAssist',
  'firstTowerKill',
  'champLevel',
  'doubleKills',
  'inhibitorKills',
  'firstInhibitorAssist',
  'pentaKills',
  'totalHeal',
  'totalMinionsKilled',
  'timeCCingOthers',
  'goldPerMinDeltas [0-10 min]',
  'goldPerMinDeltas [10-20 min]',
  'creepsPerMinDeltas [0-10 min]',
  'creepsPerMinDeltas [10-20 min]',
  'xpPerMinDeltas [0-10 min]',
  'xpPerMinDeltas [10-20 min]',
  'damageTakenPerMinDeltas [0-10 min]',
  'damageTakenPerMinDeltas [10-20 min]',
];

class About extends Component {
  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
  }

  renderDataPointList = () => (
    <ul>
      {DATA_POINTS.map(data => (
        <li key={data}>{data}</li>
      ))}
    </ul>
  );

  render() {
    return (
      <>
        <Helmet>
          <meta name="og:title" content="About" />
          <meta name="og:url" content="https://predict-gg.moky.dev/about/" />
          <meta name="twitter:title" content="About" />
          <meta name="twitter:url" content="https://predict-gg.moky.dev/about/" />
        </Helmet>
        <Row className="no-gutters box">
          <Col className="p-2 p-sm-4 box-items-container text-left about-section">
            <h5 className="text-underline">About Predict GG</h5>
            <p>
              Predict GG was a small side project I started with the intention to see if I could accurately classify
              games of League of Legends based one the previous performance of the players in the game. All historic
              data is gathered from the Riot Games API and modeled to fit. Then a neural network classifier is trained.
            </p>
            <h5>Training dataset</h5>
            <p>
              Random ranked games from various regions are selected. After that from each is extracted the list of
              participating players and the champion they play. For each player are then collected performance
              statistics from up to 30 previous ranked games where he played the same champion. Other than those a few
              datapoints regarding the user account are also taken. The collected stats are:
            </p>
            {this.renderDataPointList()}
            <h5>Predicting</h5>
            <p>
              When a prediction request is made the process for gathering data is almost the same. The biggest
              difference is the fact that only the last 10 games are evaluated for each player. This is mostly due to
              the limited amount of API request to the Riot Games API that can be made in a given timeframe. The
              "production" API limit is 500 request per 10 seconds. To not over the limit a rate limiter is implemented
              but for it to work all requests need to be executed sequentially and that is the main reason why each
              prediction takes so long.
            </p>
            <h5>Conclusion</h5>
            <p>
              There are a few really important factors that play a role in the accuracy of the classifier. The main one
              is the fact the game itself has a matchmaking algorithm implemented. It's goal is to field two teams with
              close to equal chances of winning. The other factor is the assumption that high ranking games are harder
              to predict then lower ones. This is due to the fact on high levels of play players generally know the
              capabilites of the champion they play and thus perform decently in most cases. In such games team play and
              individual decison making gains more, while raw stats lose influence in the outcome of the game. However
              more testing is needed to confirm these claims.
            </p>
          </Col>
        </Row>
      </>
    );
  }
}

export default About;
