import React from 'react';
import { Row, Col, Badge } from 'reactstrap';

const StatusQueue = ({ prediction }) => {
  return (
    <>
      <Row className="no-gutters mb-4">
        <Col xs="12">
          <h5 className="queue-notice">There are a lot of prediction requests, so a queue has formed.</h5>
        </Col>
      </Row>
      <Row className="no-gutters">
        <Col xs="12">
          <span className="px-0 px-sm-3 d-block d-sm-inline">Position In Queue:</span>
          <Badge className="mt-3 mt-sm-0 color-black" color="primary">
            <h5 className="mb-1 px-2">
              {prediction.positionInQueue} / {prediction.queueSize}
            </h5>
          </Badge>
        </Col>
      </Row>
    </>
  );
};

export default StatusQueue;
