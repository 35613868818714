import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Form, FormGroup, Input, Button } from 'reactstrap';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
import { client } from '../../config/gqlRequest';
import CreatePredictionMutation from '../../operations/createPredictionMutation';
import { Spinner } from '../../containers/Spinner';
import { errorHandler } from '../../constants/errorHandling';
import Logo from '../../containers/Logo';

const REGIONS = [
  { label: 'euw', value: 'EUW1' },
  { label: 'eune', value: 'EUN1' },
  { label: 'na', value: 'NA1' },
  { label: 'kr', value: 'KR' },
  { label: 'lan', value: 'LA1' },
  { label: 'las', value: 'LA2' },
  { label: 'oce', value: 'OC1' },
  { label: 'ru', value: 'RU' },
  { label: 'tr', value: 'TR1' },
  { label: 'jp', value: 'JP1' },
  { label: 'br', value: 'BR1' },
];

const Home = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  const history = useHistory();

  const [summonerName, setSummonerName] = useState({
    name: 'summonerName',
    value: '',
    invalid: true,
  });

  const [region, setRegion] = useState({
    name: 'region',
    value: '',
    invalid: true,
  });

  const [error, setError] = useState({
    code: null,
    message: '',
  });

  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const validate = (field, value) => {
    if (field === 'region') {
      return !REGIONS.find(region => region.value === value);
    }

    if (field === 'summonerName') {
      return value.trim().length < 1;
    }

    return true;
  };

  const inputHandler = (setField, value) => {
    setField(fieldValues => ({ ...fieldValues, value, invalid: validate(fieldValues.name, value) }));
    setError({ code: null, message: '' });
  };

  const submitHandler = event => {
    event.preventDefault();
    setSubmitted(true);
    if (summonerName.value.trim() && region.value && !summonerName.invalid && !region.invalid) {
      ReactGA.event({
        category: 'User',
        action: 'Requested predict',
        label: `${region.value} ${summonerName.value}`,
      });
      setLoading(true);

      client
        .request(CreatePredictionMutation, {
          summonerName: summonerName.value,
          region: region.value,
        })
        .then(data => {
          setLoading(false);
          history.push({ pathname: `report/${data.predictionCreateOne._id}` });
        })
        .catch(e => {
          const error = errorHandler(e, true);
          setLoading(false);
          setError({ code: error.code, message: error.message });
        });
    }
  };

  const renderRegions = () => {
    return REGIONS.map(region => (
      <option key={region.value} value={region.value}>
        {region.label.toUpperCase()}
      </option>
    ));
  };

  return (
    <>
      <Helmet>
        <meta name="og:title" content="Predict GG" />
        <meta name="og:url" content="https://predict-gg.moky.dev/" />
        <meta name="twitter:title" content="Predict GG" />
        <meta name="twitter:url" content="https://predict-gg.moky.dev/" />
      </Helmet>
      <Logo />
      <Row className="no-gutters box">
        <Col className="box-items-container">
          {error.code && (
            <Row className="no-gutters mb-4 error-header">
              <Col xs="12">
                <h5>{error.message}</h5>
              </Col>
            </Row>
          )}
          <Form onSubmit={submitHandler} inline>
            <Col xs="12" sm="7" md="6" lg="7">
              <FormGroup>
                <Input
                  aria-label="Summoner Name"
                  autoComplete="off"
                  invalid={submitted && summonerName.invalid}
                  className="w-100"
                  placeholder="Summoner Name"
                  type="text"
                  id="summonerName"
                  value={summonerName.value}
                  onChange={event => inputHandler(setSummonerName, event.target.value)}
                />
              </FormGroup>
            </Col>
            <Col xs="12" sm="5" md="3">
              <FormGroup>
                <Input
                  aria-label="Region"
                  invalid={submitted && region.invalid}
                  className="w-100"
                  type="select"
                  id="region"
                  value={region.value}
                  onChange={event => inputHandler(setRegion, event.target.value)}>
                  <option disabled value="">
                    Region
                  </option>
                  {renderRegions()}
                </Input>
              </FormGroup>
            </Col>
            <Col className="mt-0 mt-sm-3 mt-md-0" xs="12" md="3" lg="2">
              <FormGroup>
                <Button disabled={loading} block className="button-predict px-3" color="orange">
                  {loading ? (
                    <Spinner className="d-flex aling-items-center justify-content-center" size="sm" />
                  ) : (
                    'Predict'
                  )}
                </Button>
              </FormGroup>
            </Col>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default Home;
