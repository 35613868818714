import React from 'react';
import { Row, Col, Table } from 'reactstrap';
import { range, max, round } from 'lodash-es';
import Image from 'react-graceful-image';

const RenderPlayers = ({ prediction }) => {
  const blueTeamPlayers = prediction.players.filter(player => player.teamId === 100);
  const redTeamPlayers = prediction.players.filter(player => player.teamId === 200);
  return (
    <Table className="players-table">
      <tbody>
        {range(0, prediction.players.length / 2).map(index => (
          <tr key={index}>
            <td className="text-left">
              <Image
                width="30"
                height="30"
                className="img-fluid champion-icon"
                src={blueTeamPlayers[index].championImageUrl}
                alt={blueTeamPlayers[index].championName}
                placeholderColor="#222222"
              />
              <span className="name ml-1 ml-sm-3">{blueTeamPlayers[index].summonerName}</span>
            </td>
            <td className="text-right">
              <span className="d-none d-sm-inline d-sm-name mr-0 mr-sm-3">{redTeamPlayers[index].summonerName}</span>
              <Image
                width="30"
                height="30"
                className="img-fluid champion-icon"
                src={redTeamPlayers[index].championImageUrl}
                alt={redTeamPlayers[index].championName}
                placeholderColor="#222222"
              />
              <span className="d-block d-sm-none d-sm-name mr-0 mr-sm-3">{redTeamPlayers[index].summonerName}</span>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const StatusDone = ({ prediction }) => {
  const blueTeamWin = prediction.prediction.indexOf(max(prediction.prediction)) === 0;
  const redTeamWin = prediction.prediction.indexOf(max(prediction.prediction)) === 1;

  const blueTeamChance = round(prediction.prediction[0] * 100, 0);
  const redTeamChance = round(prediction.prediction[1] * 100, 0);

  return (
    <>
      <Col xs="12" className="px-0">
        <h3 className="color-orange text-uppercase">Which team will win?</h3>
      </Col>
      <Row className="no-gutters">
        <Col xs="6" className="pr-1 pr-sm-0">
          <span className={(blueTeamWin ? 'blue-team' : 'red-team') + ' text-uppercase px-2'}>
            {blueTeamChance === 100 ? 'Victory' : blueTeamChance === 0 ? 'Defeat' : `${blueTeamChance}%`}
          </span>
        </Col>
        <Col xs="6" className="pl-1 pl-sm-0">
          <span className={(redTeamWin ? 'blue-team' : 'red-team') + ' text-uppercase px-2'}>
            {redTeamChance === 100 ? 'Victory' : redTeamChance === 0 ? 'Defeat' : `${redTeamChance}%`}
          </span>
        </Col>
      </Row>
      <Row className="no-gutters">
        <Col xs="12" md={{ offset: 1, size: 10 }}>
          <RenderPlayers prediction={prediction} />
        </Col>
      </Row>
    </>
  );
};

export default StatusDone;
