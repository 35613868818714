import Home from '../views/Home';
import Report from '../views/Report';
import About from '../views/About';

export const ROUTE_TO_HOME_PAGE = '/';
export const ROUTE_TO_REPORT_PAGE = '/report/:id([a-zA-Z0-9]+)';
export const ROUTE_TO_ABOUT_PAGE = '/about';

export const routes = [
  { path: ROUTE_TO_HOME_PAGE, exact: true, name: 'Home', component: Home },
  {
    path: ROUTE_TO_REPORT_PAGE,
    exact: true,
    name: 'Report',
    component: Report,
  },
  { path: ROUTE_TO_ABOUT_PAGE, exact: true, name: 'About', component: About },
];
