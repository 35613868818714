import React, { useState } from 'react';
import { NavLink as Link } from 'react-router-dom';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import { ROUTE_TO_HOME_PAGE, ROUTE_TO_ABOUT_PAGE } from '../../constants/routes';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(openState => !openState);
  };

  return (
    <Navbar sticky="top" light expand="md">
      <NavbarBrand tag={Link} to={ROUTE_TO_HOME_PAGE}>
        Predict GG
      </NavbarBrand>
      <NavbarToggler aria-label="Toggle navigation" onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="ml-auto" navbar>
          <NavItem>
            <NavLink tag={Link} to={ROUTE_TO_HOME_PAGE} exact={true}>
              Home
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to={ROUTE_TO_ABOUT_PAGE} exact={true}>
              About
            </NavLink>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default Header;
