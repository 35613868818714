import React from 'react';
import { Col, Progress, Input } from 'reactstrap';

const StatusProcessing = ({ prediction }) => {
  return (
    <>
      <Col>
        <div>
          <Progress color="success" animated value={prediction.processing.completionPercentage}>
            {`${prediction.processing.completionPercentage}%`}
          </Progress>
        </div>
      </Col>
      <Col>
        <Input type="textarea" id="reportLog" name="report log" readOnly value={prediction.log.join('\r\n')} />
      </Col>
    </>
  );
};

export default StatusProcessing;
