import React from 'react';
import { Spinner as ReactstrapSpinner } from 'reactstrap';

export const Spinner = ({ size = 'lg', color = '', className = '', loaderClassName = '' }) => {
  return (
    <div className={`text-center ${className}`.trim()}>
      <ReactstrapSpinner className={loaderClassName} size={size} color={color} />
    </div>
  );
};
