import React from 'react';
import { Row, Col } from 'reactstrap';
import logo from '../../img/predictGG.png';
import Image from 'react-graceful-image';

const Logo = () => (
  <Row className="no-gutters mt-5 mb-3">
    <Col className="text-center">
      <Image width="800" height="300" className="img-fluid" src={logo} placeholderColor="#333339" alt="Predict GG" />
    </Col>
  </Row>
);

export default Logo;
